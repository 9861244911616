<template>
    <div class="jdt-report">
        <div class="jdt-report-content">
            <div class="top-bar" v-if="isAdministrator">
                <div 
                    class="top-bar-btn"
                    :class="{'active': index == activeIndex}"
                    v-for="(item, index) in topNavs"
                    :key="'topNavs_' + index"
                    v-text="item.label"
                    @click="topNavTap(index)"
                    >成员</div>
                <!-- <div class="top-bar-btn">活动</div> -->
            </div>

            <div class="jdt-report-title" v-if="activeIndex == 0">
                <div class="table-title">
                    {{ (titleArr[achievementDetailType] || '') + '：'}}
                    <span>{{ totalCount }}</span>
                </div>

                <div class="filter-bar">
                    <el-select 
                        v-if="activeIndex == 0"
                        class="filter-type"
                        v-model="achievementDetailType" 
                        @change="handleTypeChange"
                        placeholder="请选择">
                        <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>

                    <el-date-picker
                        v-if="activeIndex == 0"
                        class="filter-date"
                        v-model="dateRange"
                        type="daterange"
                        align="center"
                        unlink-panels
                        range-separator="~"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :picker-options="expireTimeOption"
                        @change="handleDate"
                        >
                    </el-date-picker>
                    <el-date-picker
                        v-else
                        class="filter-date"
                        v-model="activityDateRange"
                        type="daterange"
                        align="center"
                        unlink-panels
                        range-separator="~"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :picker-options="pickerOptions"
                        @change="handleDate"
                        >
                    </el-date-picker>

                    <div class="employees" v-show="isAdministrator && activeIndex == 0">
                        <el-popover
                            popper-class="team-member-popover static-team-member-popover"
                            placement="bottom-end"
                            width="700"
                            trigger="click"
                            v-model="teamMemberPopoverVisible"
                        >
                            <team-member-select
                                ref="teamSelect"
                                :isAllMembers="true"
                                :isShowDimission="false"
                                :date="dateRange"
                                :isHistoryData="true"
                                :visible="teamMemberPopoverVisible"
                                :selected="userIds"
                                @item-select-cb="employeeSelect"
                            ></team-member-select>
                            <div slot="reference" class="employee-select-text">
                                <i class="icon-font icon-user-select doll visible"></i>
                                <span class="text">{{selectedMembersText}}</span>
                                <i v-if="!teamMemberPopoverVisible" class="employee-select-arrow fa fa-caret-down doll visible"></i>
                                <i v-else class="employee-select-arrow fa fa-caret-up doll visible"></i>
                            </div>
                        </el-popover>
                    </div>

                    <el-button 
                        v-if="activeIndex == 0"
                        class="export-btn"
                        :loading="exportLoading" 
                        type="primary" 
                        @click="handleExport"
                    >导出明细</el-button>
                </div>
            </div>

            <div class="jdt-report-table">
                <el-table
                    v-loading="loading"
                    :data="tableData"
                    border
                    @cell-mouse-enter="handleCellMouseEnter"
                    tooltip-effect="light">

                    <el-table-column
                        v-for="item in currentTableTitle"
                        :key="item.field"
                        :prop="item.field"
                        align="left"
                        :class="item.class"
                        :min-width="item.width"
                        :show-overflow-tooltip="true"
                        :resizable="false"
                        :label="formatLabel(item)">
                    </el-table-column>
                </el-table>

                <div class="work-table-footer">
                    <el-pagination :current-page="page" :page-sizes="[15, 20, 50]" :page-size="pageSize" class="el-pagination-workTable" layout="total, sizes, prev, pager, next, slot" :total="totalCount" @size-change="handleSizeChange" @current-change="handleCurrentChange">
                        <span class="pagination-text">
                            <span>前往<el-input v-model="pagerJump" @keyup.enter.native="handlePagerJump"></el-input>页</span>
                            <span @click="handlePagerJump">跳转</span>
                        </span>
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import { jdt as jdtUrl } from '#/js/config/api.json';
    import { jdt as jdtJavaUrl } from '#/js/config/javaApi.json';
    import { currency as currencyFilter } from '#/js/filters/number.js';
    import myService from '#/js/service/myService.js';
    import {downloadFile} from '#/js/util/fileUtil.js';
    import TeamMemberSelect from '#/component/common/team-member-select.vue';

    const YESTODAY = moment().subtract(1, 'days').format("YYYY-MM-DD");
    const lastMonth = moment().subtract(1, 'months').format("YYYY-MM-DD");

    export default {
        name: "JdtReport",
        components: {
            TeamMemberSelect
        },
        data() {
            let _minTime = null,
            _maxTime = new Date().getTime();
            return {
                loading: false,
                exportLoading: false,
                isFirstFetch: true,
                currentTableTitle: [],
                tableTitle: [
                    {
                        field: 'headHuntingRealName',
                        label: '成员名称',
                        class: '',
                        width: '100'
                    },
                    {
                        field: 'nickName',
                        label: '候选人昵称',
                        class: '',
                        width: '74'
                    },
                    {
                        field: 'realName',
                        label: '候选人',
                        class: '',
                        width: '50'
                    },
                    {
                        field: 'title',
                        label: '职位',
                        class: '',
                        width: '74'
                    },
                    {
                        field: 'company',
                        label: '公司',
                        class: '',
                        width: '74'
                    },
                    {
                        field: 'date',
                        label: '分享时间',
                        class: '',
                        width: '74'
                    }
                ],
                activityTableTitle: [
                    {
                        field: 'title',
                        label: '活动标题',
                        class: '',
                        width: '200'
                    },
                    {
                        field: 'recommendRegisterCnt',
                        label: '连接人数',
                        class: '',
                        width: '174'
                    },
                    {
                        field: 'rangeDate',
                        label: '活动时间',
                        class: '',
                        width: '274'
                    },
                ],
                totalCount: 0,
                page: 1,
                pageSize: 15,
                pagerJump: 0,
                startDate: '',
                endDate: '',
                userIds: [],
                achievementDetailType: '',
                type: -1,
                titleArr: ['职通车推荐注册人数', '分享职位数', '发消息给我的人数', '我发消息的人数'],
                tableData: [],
                dateRange: [],
                // defaultDateRange: [],
                activityDateRange: [],
                isFocusDate: false,//用于控制日期的三角
                expireTimeOption: {
                    disabledDate(date) {
                        return date.getTime() > Date.now();
                    },
                    shortcuts: [{
                        text: '昨天',
                        onClick(picker) {
                            const end = moment().subtract(1, 'days').format('YYYY-MM-DD');
                            const start = moment().subtract(1, 'days').format('YYYY-MM-DD');
                            picker.$emit('pick', [start, end]);
                        }
                    },{
                        text: '今天',
                        onClick(picker) {
                            const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                            const start = moment().subtract(0, 'days').format('YYYY-MM-DD');
                            picker.$emit('pick', [start, end]);
                        }
                    },{
                        text: '最近7天',
                        onClick(picker) {
                            const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                            const start = moment().subtract(6, 'days').format('YYYY-MM-DD');
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近30天',
                        onClick(picker) {
                            const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                            const start = moment().subtract(29, 'days').format('YYYY-MM-DD');
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '本月',
                        onClick(picker) {
                            const start = moment().add('month', 0).format('YYYY-MM') + '-01';
                            const end = moment(start).add('month', 1).add('days', -1).format('YYYY-MM-DD');
                            picker.$emit('pick', [start, end]);
                        }
                    },{
                        text: '上月',
                        onClick(picker) {
                            const start = moment().subtract(1, 'month').format('YYYY-MM') + '-01';
                            const end = moment(start).subtract(-1, 'month').add('days', -1).format('YYYY-MM-DD');
                            picker.$emit('pick', [start, end]);
                        }
                    },{
                        text: '今年',
                        onClick(picker) {
                            const start = moment().year() + '-01-01';
                            const end = moment().format('YYYY-MM-DD');
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                pickerOptions: {
                    onPick: time => {
                        if (!time.maxDate) {
                            let timeRange = 365*24*60*60*1000, // *天
                                _dNow = new Date().getTime();

                            _minTime = time.minDate.getTime() - timeRange; // 最小时间
                            _maxTime = time.minDate.getTime() + timeRange; // 最大时间
                            // _maxTime = moment(_maxTime).isSameOrAfter(_dNow) ? _dNow: _maxTime;
                        } else {
                            _minTime = _maxTime = null;
                        }
                    },
                    disabledDate: time => {
                        // onPick后触发
                        if(_minTime && _maxTime){
                            return time.getTime() < _minTime || time.getTime() > _maxTime;
                        }
                        if(!_minTime && _maxTime) {
                            return time.getTime() > _maxTime;
                        }
                    },
                    shortcuts: [{
                        text: '昨天',
                        onClick(picker) {
                            const end = moment().subtract(1, 'days').format('YYYY-MM-DD');
                            const start = moment().subtract(1, 'days').format('YYYY-MM-DD');
                            picker.$emit('pick', [start, end]);
                        }
                    },{
                        text: '今天',
                        onClick(picker) {
                            const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                            const start = moment().subtract(0, 'days').format('YYYY-MM-DD');
                            picker.$emit('pick', [start, end]);
                        }
                    },{
                        text: '最近7天',
                        onClick(picker) {
                            const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                            const start = moment().subtract(6, 'days').format('YYYY-MM-DD');
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近30天',
                        onClick(picker) {
                            const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                            const start = moment().subtract(29, 'days').format('YYYY-MM-DD');
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '本月',
                        onClick(picker) {
                            const start = moment().add('month', 0).format('YYYY-MM') + '-01';
                            const end = moment(start).add('month', 1).add('days', -1).format('YYYY-MM-DD');
                            picker.$emit('pick', [start, end]);
                        }
                    },{
                        text: '上月',
                        onClick(picker) {
                            const start = moment().subtract(1, 'month').format('YYYY-MM') + '-01';
                            const end = moment(start).subtract(-1, 'month').add('days', -1).format('YYYY-MM-DD');
                            picker.$emit('pick', [start, end]);
                        }
                    },{
                        text: '今年',
                        onClick(picker) {
                            const start = moment().year() + '-01-01';
                            const end = moment().format('YYYY-MM-DD');
                            picker.$emit('pick', [start, end]);
                        }
                    }],
                },
                offerLogLoading: false,
                editOfferLogList: [],

                options: [
                    {
                        value: 0,
                        label: '职通车推荐注册人数'
                    }, 
                    {
                        value: 1,
                        label: '分享职位数'
                    }, 
                    {
                        value: 2,
                        label: '发消息给我的人数'
                    }, 
                    {
                        value: 3,
                        label: '我发消息的人数'
                    }
                ],
                // typeValue: '1',
                teamMemberPopoverVisible: false,
                selectedMembersText: '全部成员',
                userIds: [],

                // isActivity: false, // 是否为活动
                activeIndex: 0,
                topNavs: [
                    {label: '成员'}, 
                    {label: '活动'}, 
                ]
            }
        },
        computed: {
            userInfo() {
                return this.$store.state.user.userInfo;
            },
            firmId() {
                return this.$store.state.user.userInfo.firmId;
            },
            isCaculateOpen() {
                return this.$store.state.user.userInfo.isOpenPerformanceCalculation;
            },
            isAdministrator() {
                return this.$store.state.user.userInfo.isAdministrator;
            },
        },
        filters: {
            numPointFilter(val) {
                return val.replace('.00', '');
            },
            dataFilter(val) {
                return moment(val).format('YYYY-MM-DD HH:mm:SS')
            }
        },
        created() {
        },
        mounted() {
            // if(sessionStorage.getItem('perpormancesDetailData')) {
            // }else if(localStorage.getItem('perpormancesDetailData')) {
            //     let jsonStr = localStorage.getItem('perpormancesDetailData');
            //     sessionStorage.setItem('perpormancesDetailData', jsonStr);
            // }
            
            let json = JSON.parse(sessionStorage.getItem('perpormancesDetailData')) || {};
            this.startDate = json.startDate || lastMonth;
            this.endDate = json.endDate || YESTODAY;
            this.userIds = json.userIds || [];
            if(this.isAdministrator == false) {
                this.userIds = [this.userInfo.id];
            }
            this.achievementDetailType = parseInt(json.jdtReportDataType || 0);
            this.dateRange = [this.startDate, this.endDate];
            this.activityDateRange = [lastMonth, YESTODAY];
            this.currentTableTitle = this.tableTitle;
            
            if(this.isAdministrator) {
                if(this.$refs.teamSelect) {
                    this.$refs.teamSelect.getHistoryTeamData(true);
                } else {
                    this.getData();
                }
            } else {
                this.getData();
            }
        },
        methods: {
            topNavTap(index) {
                if(this.loading) {
                    return shortTips(`切换太频繁，请数据加载完后再切换。`);
                };
                this.activeIndex = index;
                this.page = 1;
                this.pageSize = 10;
                // this.dateRange = this.defaultDateRange;
                if(index == 1) {
                    this.currentTableTitle = this.activityTableTitle;
                    this.getActivityData();
                } else {
                    this.currentTableTitle = this.tableTitle;
                    this.getData();
                }
            },
            getActivityData() {
                const _start = moment(this.activityDateRange[0]).format("YYYY-MM-DDTHH:mm:ss.SSS"),
                    _end = moment(this.activityDateRange[1]).add(1, 'days').subtract(1, 'seconds').format("YYYY-MM-DDTHH:mm:ss.SSS");
                const params = {
                    currentPage: this.page,
                    pageSize: this.pageSize,
                    startDate: _start,
                    endDate: _end,
                };
                
                this.loading = true;
                _request({
                    method: 'POST',
                    url: jdtJavaUrl.jdt_activity_report_list,
                    baseURL: 'LbdJavaApi',
                    javaProject: 'jdt',
                    data: params,
                }).then(res =>{
                    this.totalCount = res.count;
                    this.tableData = res.data.map(item => ({
                        ...item,
                        rangeDate: item.startDate + '/' + item.endDate
                    })) || [];
                }).finally(() => {
                    this.loading = false;
                    // 刷新定位到页面顶部
                    // const _scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
                    const _scrollTop = document.querySelector(".jdt-report").scrollTop;
                    if(_scrollTop > 200) {
                        // document.documentElement.scrollTop = 0;
                        // document.body.scrollTop = 0;
                        document.querySelector(".jdt-report").scrollTop = 0;
                    }
                });
            },
            formatLabel(item) {
                if(item.field == 'date') {
                    if(this.achievementDetailType == 0) {
                        return '注册时间';
                    }else if(this.achievementDetailType == 1) {
                        return '分享时间';
                    }else {
                        return '联系时间';
                    }
                }else if(item.field == 'title' || item.field == 'company') {
                    if(this.achievementDetailType == 1) {
                        return '分享' + item.label
                    }else {
                        return item.label
                    }
                    
                }else {
                    return item.label
                }
            },
            generateData(param) {
                let data;
                if(param && param.isExport) {
                    data = {
                        offset: 0,
                        pageSize: 1000
                    }
                }else {
                    data = {
                        offset: (this.page - 1) * this.pageSize,
                        pageSize: this.pageSize,
                        achievementDetailType: this.achievementDetailType+1
                    }
                }
                if(this.startDate) data.startDate = this.startDate.replace(/\//g, '-');;
                if(this.endDate) data.endDate = this.endDate.replace(/\//g, '-');;
                if(this.userIds.length > 0) data.userIds = this.userIds;
                return data;
            },
            getData() {
                this.loading = true;
                let data = this.generateData();
                
                _request({
                    method: 'POST',
                    url: jdtUrl.achievement_detail,
                    baseURL: 'LbdJavaApi',
                    javaProject: 'performance',
                    data: data
                }).then(res =>{
                    if(this.achievementDetailType == 1 && res.data && res.data.length > 0) {
                        res.data.forEach(item => {
                            item.company = item.customerName;
                            item.title = item.jobName;
                        })
                    }
                     if(this.achievementDetailType == 0) {
                        res.data.forEach(item => {
                            if(item.date && item.date.indexOf('T') > -1) {
                                item.date = item.date.replace('T', ' ');
                            }
                        })
                    }
                    this.totalCount = res.count;
                    this.tableData = res.data || [];
                    this.loading = false;
                    this.isFirstFetch = false;
                    // 刷新定位到页面顶部
                    // const _scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
                    const _scrollTop = document.querySelector(".jdt-report").scrollTop;
                    if(_scrollTop > 200) {
                        // document.documentElement.scrollTop = 0;
                        // document.body.scrollTop = 0;
                        document.querySelector(".jdt-report").scrollTop = 0;
                    }
                })
            },
            handlePagerJump() {
                let currentPager = Number(this.pagerJump),
                currentPageTotal = Math.ceil(this.totalCount/this.pageSize);
                if(currentPager > 0 && currentPager <= currentPageTotal){
                    this.page = currentPager;
                    this.unionRequest();
                }
            },
            handleCurrentChange(val){
                this.page = val;
                this.unionRequest();
            },
            handleSizeChange(val){
                this.page = 1;
                this.pageSize = val;
                this.unionRequest();
            },
            unionRequest() {
                if(this.activeIndex == 1) {
                    this.getActivityData();
                } else {
                    this.getData();
                }
            },
            columnFormatter(column, cellValue) {
                if(column == 'offerTime' || column == 'hireTime') {
                    return cellValue ? moment(cellValue).format('YYYY-MM-DD') : ''
                }else if(column == 'serviceCharge' || column == 'performance') {
                    return currencyFilter(cellValue, '', 0);
                }else {
                    return cellValue;

                }
            },
            handleExport() {
                if(this.exportLoading) {
                    return false
                }
                this.exportLoading = true;
                let data = this.generateData({ isExport: true });
                data.achievementDetailType = this.achievementDetailType + 1;
                // let tempWindow = window.open('', '_self');
                let url = jdtUrl.export_register_user;
                // switch (this.achievementDetailType) {
                //     case 0:
                //         url = jdtUrl.export_register_user;
                //         break;
                //     case 1:
                //         url = jdtUrl.export_job_share;
                //         break;
                //     case 2:
                //         url = jdtUrl.export_jdt_msg;
                //         data.achievementDetailType = 2;
                //         break;
                //     case 3:
                //         url = jdtUrl.export_jdt_msg;
                //         data.achievementDetailType = 3;
                //         break;
                //     default:
                //         url = jdtUrl.export_register_user;
                // }
                _request({
                    method: 'POST',
                    url: url,
                    data: data,
                    baseURL: 'LbdJavaApi',
                    javaProject: 'performance',
                    responseType: 'blob',
                }).then(res =>{
                     // java接口这边采用的是文件流返回，需要写入
                    downloadFile(res);
                }).finally(() => {
                    this.exportLoading = false;
                })
            },
            handleCellMouseEnter(row, column, cell, event, flag) {
                let popperEleArr = document.getElementsByClassName('el-tooltip__popper is-light');
                if(popperEleArr && popperEleArr.length > 0) {
                    Array.prototype.forEach.call(popperEleArr, (popperEle) => {
                        popperEle.innerText = cell.innerText;
                        if(popperEle.innerText.indexOf('\n') > -1) {
                            let html = popperEle.innerText.replace('\n', '<br />');
                            popperEle.innerHTML = popperEle.innerHTML.replace(popperEle.innerText, html);
                        }
                    })
                }else {
                    if(!flag) {
                        setTimeout(() =>{
                            this.handleCellMouseEnter(row, column, cell, event, true);
                        }, 100)
                    }
                }
            },
            handleDate(value) {
                if(this.activeIndex == 0) {
                    this.startDate = moment(value[0]).format('YYYY-MM-DD');
                    this.endDate = moment(value[1]).format('YYYY-MM-DD');
                    const jsonStr = {
                        startDate: this.startDate,
                        endDate: this.endDate,
                    };
                    sessionStorage.setItem('perpormancesDetailData', JSON.stringify(jsonStr));
                }
                this.handleCurrentChange(1);
            },
            handleFocusDate() {
                this.isFocusDate = true;
            },
            handleBlurDate() {
                this.isFocusDate = false;
            },
            transformOfferLog(log) {
                let list = [],
                    text = "",
                    itemSplitItemText = "",
                    itemSplitItems = [];
                Object.keys(log).forEach(item => {
                    if(item !== 'performanceSplits' && item !== 'months' && ((item === 'serviceCharge' && log[item] != undefined) || (item !== 'serviceCharge' && log[item]))) {
                        switch(item) {
                            case 'monthPay':
                                text = `<p>签订月薪为：${log.months}*${log.monthPay}k</p>`;
                                break;
                            case 'recruiteTime':
                                text = `<p>入职时间为：${moment(log.recruiteTime).format("YYYY-MM-DD")}</p>`;
                                break;
                            case 'commissionRate':
                                text = `<p>佣金比例为：${log.commissionRate}%</p>`;
                                break;
                            case 'serviceCharge':
                                text = `<p>服务费为：${log.serviceCharge}</p>`;
                                break;
                            case 'remark':
                                text = `<p>备注为：${log.remark}</p>`;
                                break;
                        }
                        list.push(text);
                    }
                });
                
                log.performanceSplits.forEach(item =>{
                    text = `<p>${item.name}为：`;
                    itemSplitItemText = "";
                    itemSplitItems = [];
                    for(let i = 0; i < item.splitItems.length; i++) {
                        itemSplitItemText = `${item.splitItems[i].realName}，`;
                        if(item.type > 0) {
                            itemSplitItemText += `比例为 ${item.splitItems[i].value}%`;
                        } else {
                            itemSplitItemText += `数值为 ${item.splitItems[i].value}`;
                        }
                        itemSplitItems.push(itemSplitItemText);
                    }
                    text += itemSplitItems.join("；");
                    text += '</p>';
                    list.push(text);
                });
                return list.join("");
            },
            getEditLogList(recommendId) {
                if(!this.editOfferLogList.length) {
                    this.offerLogLoading = true;
                    myService.getOfferEditLog(recommendId).then(res => {
                        this.offerLogLoading = false;
                        this.editOfferLogList = res && res.length ? res : [];
                    }).catch(err => {
                        this.offerLogLoading = false;
                        console.log(err);
                    });
                }
            },

            // 员工选取
            employeeSelect(userIds, text) {
                if(userIds && userIds.length > 0) {
                    this.userIds = userIds;
                } else {
                    this.userIds = [this.userInfo.id];
                }
                
                this.teamMemberPopoverVisible = false;
                this.selectedMembersText = text || '全部成员';

                this.getData();
            },
            // 切换类型
            handleTypeChange() {
                this.page = 1;
                this.pageSize = 10;

                this.getData();
            },  
        }
    }
</script>

<style lang="scss" scope>
    .team-member-popover.delivery-team-member-popover {
        transform: translateX(0);
    }
    .jdt-report {
        padding: 20px;
        height: 100%;
        overflow-y: auto;

        &-content {
            padding-bottom: 38px;
            background-color: #fff;
            border-radius: 8px;
            min-height: calc(100vh - 140px);
        }

        .top-bar{
            display: flex;
            flex-flow: row nowrap;
            border-bottom: 1px solid #eee;
            .top-bar-btn{
                margin-left: 32px;
                padding: 0 18px;
                height: 60px;
                line-height: 60px;
                box-sizing: border-box;
                font-size: 16px;
                font-family: 'Microsoft YaHei';
                color: #999999;
                text-align: center;
                cursor: pointer;
            }
            .active{
                color: #38BC9D;
                border-bottom: 4px solid #38BC9D;
            }
        }

        &-title {
            display: flex;
            justify-content: space-between;
            line-height: 32px;
            padding: 30px 30px 0;
            margin-bottom: 8px;

            .table-title {
                font-size: 28px;
                color: #666;
                line-height: 32px;

                > span {
                    color: $primary;
                }
            }

            &-btn {
                position: absolute;
                top: 0;
                right: 0;
                margin-top: 32px;
            }

            .filter-bar{
                display: flex;
                align-content: center;
                input.el-input__inner {
                    height: 36px;
                }
                .filter-type{
                    width: 200px;
                    height: 36px;
                    margin-right: 20px;
                }
                .filter-date{
                    width: 280px;
                    height: 36px;
                    margin-right: 20px;
                }

                .employees {
                    position: relative;
                    display: inline-block;
                    width: 190px;
                    height: 36px;
                    line-height: 36px;
                    padding: 0 12px;
                    border: 1px solid #d3d3d3;
                    border-radius: 4px;
                    vertical-align: bottom;
                    .employee-select-text {
                        display: flex;
                        align-items: center;
                        .text {
                            flex: 1;
                            flex-grow: 1;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                    .employee-select-arrow {
                        margin-right: 0;
                    }
                }
                .export-btn{
                    // float: right;
                    // margin-top: 8px;
                    padding: 0 20px;
                    height: 36px;
                    margin-left: 20px;
                }
            }
        }

        &-table {
            padding-top: 20px;
        }

        .cell-share-data {
            width: 136px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .table-header-tip-cell .table-header-tip-icon {
            top: 0;
        }

        .el-table thead th {
            // border: 0 none;
            // background-color: #f8f8f8;
            background-color: #F0F4F7;
            color: #666;
            font-weight: bold;
            .cell {
                padding: 0 20px;
            }
        }
        .el-table td {
            // border: 0 none;
        }
        .el-table th, .el-table td {
            padding: 8px 0;
        }

        .work-table-footer {
            margin: 35px 35px 0 0;
        }

        .date-wrap {
            position: relative;
            cursor: pointer;
            width: 190px;
            margin: 0 auto 16px;
            &:hover{
                .el-date-editor--daterange.el-input__inner{
                    border-color:#38BC9D;
                    background: #38BC9D;
                    .el-range-input{
                        background: #38BC9D !important;
                        color: #fff !important;;
                    }
                    .el-range-separator{
                        color: #fff;
                    }
                }
            }
            .el-range-editor.is-active{
                background: #38BC9D;
                .el-range-input{
                    background: #38BC9D;
                    color: #fff;
                }
                .el-range-separator{
                    color: #fff;
                }
            }
            &.triangle-drop-up{
                &::after{
                    border-bottom: 12px solid #fff;
                }
            }
            .el-date-editor--daterange.el-input__inner{
                width: 190px;
                height: 36px;
                border-radius: 16px;
            }
            .el-date-editor .el-range__icon{
                display: none;
            }

            .el-date-editor .el-range__close-icon{
                display: none;
            }
        }
        .triangle-drop{
            &:after{
                position: absolute;
                display: block;
                content: '';
                top: 50%;
                transform: translateY(-50%);
                right: 16px;
            }
            &-down{
                &:after{
                    border-top: 12px solid #ddd;
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                }
                &:hover{
                    &:after{
                        border-top: 12px solid #fff;
                    }
                }

            }
            &-up{
                &:after{
                    border-bottom: 12px solid #ddd;
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                }
                &:hover{
                    &:after{
                        border-bottom: 12px solid #fff;
                    }
                }
            }
        }
    }
</style>
<style lang="scss">
    .layout-transition {
        height: 100%;
        display: flex;
        flex-direction: column;
        background: #f9f9f9;
    }
    .performance-data-range.el-date-range-picker {
        .popper__arrow {
            left: 50% !important;
        }
    }
    .offer-edit-log-popper.el-popper {
        padding: 0;
        .offer-edit-log-list {
            max-height: 560px;
            overflow: hidden;
            overflow-y: auto;
            padding: 20px;
            .offer-edit-log-item {
                p {
                    color: #999;
                    line-height: 20px;
                    margin-bottom: 0;
                }
                .offer-info-time {
                    margin-bottom: 4px;
                    color: #666;
                }
                .offer-log-title {
                    color: #333;
                    line-height: 20px;
                    .offer-log-updator {
                        color: $primary;
                        margin-right: 5px;
                    }
                }
                & + .offer-edit-log-item {
                    margin-top: 20px;
                }
            }
        }
    }
</style>