var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "jdt-report" }, [
    _c("div", { staticClass: "jdt-report-content" }, [
      _vm.isAdministrator
        ? _c(
            "div",
            { staticClass: "top-bar" },
            _vm._l(_vm.topNavs, function (item, index) {
              return _c(
                "div",
                {
                  key: "topNavs_" + index,
                  staticClass: "top-bar-btn",
                  class: { active: index == _vm.activeIndex },
                  domProps: { textContent: _vm._s(item.label) },
                  on: {
                    click: function ($event) {
                      return _vm.topNavTap(index)
                    },
                  },
                },
                [_vm._v("成员")]
              )
            }),
            0
          )
        : _vm._e(),
      _vm.activeIndex == 0
        ? _c("div", { staticClass: "jdt-report-title" }, [
            _c("div", { staticClass: "table-title" }, [
              _vm._v(
                "\n                " +
                  _vm._s(
                    (_vm.titleArr[_vm.achievementDetailType] || "") + "："
                  ) +
                  "\n                "
              ),
              _c("span", [_vm._v(_vm._s(_vm.totalCount))]),
            ]),
            _c(
              "div",
              { staticClass: "filter-bar" },
              [
                _vm.activeIndex == 0
                  ? _c(
                      "el-select",
                      {
                        staticClass: "filter-type",
                        attrs: { placeholder: "请选择" },
                        on: { change: _vm.handleTypeChange },
                        model: {
                          value: _vm.achievementDetailType,
                          callback: function ($$v) {
                            _vm.achievementDetailType = $$v
                          },
                          expression: "achievementDetailType",
                        },
                      },
                      _vm._l(_vm.options, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    )
                  : _vm._e(),
                _vm.activeIndex == 0
                  ? _c("el-date-picker", {
                      staticClass: "filter-date",
                      attrs: {
                        type: "daterange",
                        align: "center",
                        "unlink-panels": "",
                        "range-separator": "~",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期",
                        "picker-options": _vm.expireTimeOption,
                      },
                      on: { change: _vm.handleDate },
                      model: {
                        value: _vm.dateRange,
                        callback: function ($$v) {
                          _vm.dateRange = $$v
                        },
                        expression: "dateRange",
                      },
                    })
                  : _c("el-date-picker", {
                      staticClass: "filter-date",
                      attrs: {
                        type: "daterange",
                        align: "center",
                        "unlink-panels": "",
                        "range-separator": "~",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期",
                        "picker-options": _vm.pickerOptions,
                      },
                      on: { change: _vm.handleDate },
                      model: {
                        value: _vm.activityDateRange,
                        callback: function ($$v) {
                          _vm.activityDateRange = $$v
                        },
                        expression: "activityDateRange",
                      },
                    }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isAdministrator && _vm.activeIndex == 0,
                        expression: "isAdministrator && activeIndex == 0",
                      },
                    ],
                    staticClass: "employees",
                  },
                  [
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          "popper-class":
                            "team-member-popover static-team-member-popover",
                          placement: "bottom-end",
                          width: "700",
                          trigger: "click",
                        },
                        model: {
                          value: _vm.teamMemberPopoverVisible,
                          callback: function ($$v) {
                            _vm.teamMemberPopoverVisible = $$v
                          },
                          expression: "teamMemberPopoverVisible",
                        },
                      },
                      [
                        _c("team-member-select", {
                          ref: "teamSelect",
                          attrs: {
                            isAllMembers: true,
                            isShowDimission: false,
                            date: _vm.dateRange,
                            isHistoryData: true,
                            visible: _vm.teamMemberPopoverVisible,
                            selected: _vm.userIds,
                          },
                          on: { "item-select-cb": _vm.employeeSelect },
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "employee-select-text",
                            attrs: { slot: "reference" },
                            slot: "reference",
                          },
                          [
                            _c("i", {
                              staticClass:
                                "icon-font icon-user-select doll visible",
                            }),
                            _c("span", { staticClass: "text" }, [
                              _vm._v(_vm._s(_vm.selectedMembersText)),
                            ]),
                            !_vm.teamMemberPopoverVisible
                              ? _c("i", {
                                  staticClass:
                                    "employee-select-arrow fa fa-caret-down doll visible",
                                })
                              : _c("i", {
                                  staticClass:
                                    "employee-select-arrow fa fa-caret-up doll visible",
                                }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.activeIndex == 0
                  ? _c(
                      "el-button",
                      {
                        staticClass: "export-btn",
                        attrs: { loading: _vm.exportLoading, type: "primary" },
                        on: { click: _vm.handleExport },
                      },
                      [_vm._v("导出明细")]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "jdt-report-table" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: {
                data: _vm.tableData,
                border: "",
                "tooltip-effect": "light",
              },
              on: { "cell-mouse-enter": _vm.handleCellMouseEnter },
            },
            _vm._l(_vm.currentTableTitle, function (item) {
              return _c("el-table-column", {
                key: item.field,
                class: item.class,
                attrs: {
                  prop: item.field,
                  align: "left",
                  "min-width": item.width,
                  "show-overflow-tooltip": true,
                  resizable: false,
                  label: _vm.formatLabel(item),
                },
              })
            }),
            1
          ),
          _c(
            "div",
            { staticClass: "work-table-footer" },
            [
              _c(
                "el-pagination",
                {
                  staticClass: "el-pagination-workTable",
                  attrs: {
                    "current-page": _vm.page,
                    "page-sizes": [15, 20, 50],
                    "page-size": _vm.pageSize,
                    layout: "total, sizes, prev, pager, next, slot",
                    total: _vm.totalCount,
                  },
                  on: {
                    "size-change": _vm.handleSizeChange,
                    "current-change": _vm.handleCurrentChange,
                  },
                },
                [
                  _c("span", { staticClass: "pagination-text" }, [
                    _c(
                      "span",
                      [
                        _vm._v("前往"),
                        _c("el-input", {
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.handlePagerJump.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.pagerJump,
                            callback: function ($$v) {
                              _vm.pagerJump = $$v
                            },
                            expression: "pagerJump",
                          },
                        }),
                        _vm._v("页"),
                      ],
                      1
                    ),
                    _c("span", { on: { click: _vm.handlePagerJump } }, [
                      _vm._v("跳转"),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }